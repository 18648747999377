export default {

	init() {

		var container = document.querySelector('form.ContactForm');

		if( container ) {

			// -- send form -- //

				var formFields = container.querySelectorAll('.field__input');
				var formFieldsRequired = container.querySelectorAll('.field__input--required');
				var formSubmit = false;
				var buttonSubmit = container.querySelector('.contactForm__submit');


				var requestData = [];
				var request = new XMLHttpRequest();
				var response;

				function callback( message , status = 'error' ) {

					document.querySelectorAll('.contactForm__callback').forEach( function( info, index ) {

						info.remove();

					});

					if( message )  {

						buttonSubmit.insertAdjacentHTML('beforebegin','<p class="contactForm__callback '+status+'">'+message+'</p>');

					}

				}

				container.addEventListener('submit', function(e) {

					e.preventDefault();

					callback();

					if( !formSubmit && !buttonSubmit.classList.contains('disable') ) {

						buttonSubmit.classList.add('disable');

						formFieldsRequired.forEach( function( input, index ) {

							if( input.type == 'checkbox' ) {

								if( !input.checked ) { 

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

							} else {

								if( !input.value ) {

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

								if( input.type == 'email' ) {

									if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

										input.classList.remove('error');

									} else {

										input.classList.add('error');

									}

								}

							}

						});

						if( container.querySelectorAll('.field__input--required.empty').length > 0 ) {

							callback( container.getAttribute('data-empty') );
							buttonSubmit.classList.remove('disable');

						} else if( container.querySelectorAll('.field__input--required.error').length > 0 ) {

							callback( container.getAttribute('data-emailError') );
							buttonSubmit.classList.remove('disable');

						} else {

							// -- Sending data -- //
							/*
								requestData = [];
								requestData.push( 'action=sendMail' );
								requestData.push( 'security='+container.querySelector('input#security').value );

								formFields.forEach( function( field,index ) {

									if( field.value ) {

										requestData.push( field.getAttribute('name')+'='+field.value );

									}

								});

								if( container.classList.contains('packageForm') ) {

									requestData.push( 'basic='+document.querySelector('h1').innerText );

									var extra = [];

									document.querySelectorAll('.singlePackage__modules .module__grid .grid__single input').forEach( function( single,index ) {

										if( single.checked ) {

											extra.push( single.closest('.grid__single').querySelector('.single__name').innerText );

										}

									});

									if( extra.length > 0 ) {

										extra = extra.join(',');
										requestData.push('extra=' + extra);

									}

								}

								requestData = requestData.join('&');

							// -- END -- //


							// -- Request -- //

								request = new XMLHttpRequest();
								request.onreadystatechange = function() {

									if( request.responseText && request.readyState == 4 && request.status == 200 ) {

										response = JSON.parse(request.responseText);
										console.log( response );

										if( response.status == 'success' ) {

											formSubmit = true;
											callback( container.getAttribute('data-sendSuccess'), 'success' );

										} else if( response.status == 'error' ) {

											buttonSubmit.classList.remove('disable');
											callback( container.getAttribute('data-sendError') );

										}

									}

								}

								// Set up our request
								request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

								// Add the required HTTP header for form data POST requests
								request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

								// Finally, send our data.
								request.send(requestData);
							*/
							// -- END -- //


							// -- test -- //
								let formData = new FormData();
								formData.append( 'action', 'sendMail');
								formData.append( 'security', container.querySelector('input#security').value );
								formFields.forEach( function( field,index ) {
									if( field.value ) {
										formData.append( field.getAttribute('name'), field.value );
									}
								});
								if( container.classList.contains('packageForm') ) {
									formData.append( 'basic', document.querySelector('h1').innerText );

									var extra = [];

									document.querySelectorAll('.singlePackage__modules .module__grid .grid__single input').forEach( function( single,index ) {
										if( single.checked ) {
											extra.push( single.closest('.grid__single').querySelector('.single__name').innerText );
										}
									});

									if( extra.length > 0 ) {
										extra = extra.join(',');
										formData.append( 'extra', extra );
									}

								}
								fetch( window.location.origin + '/wp-admin/admin-ajax.php', {
									method: 'POST',
									body: formData,	
								})
								.then( ( response ) => {
									return response.json();
								})
								.then( ( json ) => {
									if( json.status && json.status == 'success' ) {
										formSubmit = true;
										callback( container.getAttribute('data-sendSuccess'), 'success' );
									} else {
										buttonSubmit.classList.remove('disable');
										callback( container.getAttribute('data-sendError') );
									}
								})
								.catch( ( message ) => {
									console.log( message );
									buttonSubmit.classList.remove('disable');
									callback( container.getAttribute('data-sendError') );
								});

							// -- END -- //

						}

					}

				});

			// -- END -- //

		}

	},
	finalize() {

	}

}